#certificate-signing-request {
  .card-body {
    min-width: 400px;
  }

  .addSAN {
    min-width: auto;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
  }
}