.loader-container {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 1070;
  display: none;

  &.active {
    display: block;
  }

  .spinner {
    position: relative;
    left: 50%;
    top: 50%;
  }
}