.menu-container {
  margin-bottom: 0;
  padding-left: 0;

  a {
    color: #003369;
    display: block;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
    font-weight: 500;
    padding-bottom: 9px;
    text-decoration: none;
    border-bottom: 3px solid transparent;
  }

  a:hover, a.active {
    border-bottom: 3px solid #003369;
  }

  .menu-item {
    position: relative;
    margin: 0 5px;
    list-style-type: none;
    display: inline-block;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }

  .menu-item:hover {
    .sub-menu {
      visibility: visible;
      -webkit-transition-delay: 0s,0s;
      transition-delay: 0s,0s;
      opacity: 1;
      z-index: 10
    }
  }

  .sub-menu {
    position: absolute;
    left: 0;
    margin-top: 0;
    padding: 0;
    visibility: hidden;
    -webkit-transition-delay: 0s,.25s;
    transition-delay: 0s,.25s;
    -webkit-transition-duration: .25s,0s;
    transition-duration: .25s,0s;
    -webkit-transition-property: opacity,visibility;
    transition-property: opacity,visibility;
    opacity: 0;

    .menu-item {
      margin: 0;
      display: block;
    }

    a {
      padding: 10px;
      background-color: #003369;
      color: #ffffff;
      display: block;
      white-space: nowrap;
      border-bottom: 1px solid #003369;
    }

    a:hover {
      background-color: #0571ab;
    }
  }
}