html, body, #root {
  height: 100vh;
}

.st0 {
  fill: #002D59;
}


body {
  height: 100vh;
  margin: 0;
  color: #2a2a2a;
  background-color: #f0f1f4;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Dosis, Arial sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.container {
  width: 100%;
  max-width: 1000px;
}

main {
  .container {
    padding: 20px;
  }
}

.height-100-per {
  height: 100%;
}

footer {
  width: 100%;
  padding: 20px;
  margin: 0;
  position: fixed;
  bottom: 0;
}

main {
  height: 100vh;
  margin-top: -225px;
  padding-top: 225px;

  .container {
    height: 100%;
  }
}

h2 {
  font-weight: bold;
  line-height: 0.5;
  color: #0f1531;
}

.center {
  margin: auto;
}

input.form-control, select.form-control {
  border-radius: 0;
}

select.form-control {
  padding-left: 4px;
}

.center-content {
  margin: auto;
  flex-basis: unset;
  flex-grow: unset;
}

button.btn {
  min-width: 80px;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;

  &.btn-primary {
    background-color: #0f1531;
    border-color: #0f1531;
  }

  &.btn-secondary {
    background-color: white;
    color: #0f1531;
    border-color: #cccccc;
  }
}

.header {
  line-height: 26px;
  position: relative;
  width: 100%;
  height: auto;
  color: white;

  .logo {
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;

    svg {
      height: 50px;
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }

  nav {
    display: inline-block;
  }

  #organization-selector {
    float: right;
  }
}

.banner-container {
  height: 230px;
  position: relative;

  .banner-img-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  img {
    position: absolute;
    width: 100%;
    min-width: 1440px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-filter: blur(0);
  }

  .banner-title {
    position: relative;
    display: inline-block;
    text-align: left;
  }

  .banner-box {
    font-size: 30px;
    position: relative;
    background-color: #00a9c9;
    z-index: 1;
    line-height: 60px;
    white-space: nowrap;
    color: #fff;
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
  }

  .banner-box-light {
    z-index: 2;
    background-color: #00a9c9;
  }

  .banner-box-normal {
    background-color: #0571ab;
    top: -10px;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }

  .banner-box-dark {
    background-color: #003369;
    -webkit-transform: translateX(10%);
    -ms-transform: translateX(10%);
    transform: translateX(10%);
    z-index: 1;
    top: -10px;
  }
}

.card {
  background: none;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;

  .card-title {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 8px;
  }
}

.margin-auto {
  margin: auto;
  flex-basis: unset;
  flex-grow: unset;
}

.toast-container {
  position: fixed;
  width: 250px;
  z-index: 1060;
  right: 0;

  .toast-header, .toast-body {
    background: white;
  }
}

input[type=password] {
  width: 100%;
}

.form-group {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

#activate-page {
  input[name="firstName"] {
    margin-right: 10px;
  }

  .col {
    padding-left: 0;
    padding-right: 0;
  }

  .person {
  }
}

#login-page {
  input {
    width: 100%;
  }

  .card-body {
    width: 250px;
  }
}

.show {
  display: block;
}

#credentials-page {
  .btn-container {
    display: block;
  }

  .files-container {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;

  }

  .finish {
    float: right;
  }
}

.dropzone {
  border: 2px dashed #cccccc;
  outline: none;
  transition: border .24s ease-in-out;

  .dropzone-files-container {
    margin-top: 20px;
    font-weight: bold;
  }
}

.tab-content {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 10px;
}

.code {
  font-family: monospace;
  font-size: 11px;
  padding: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #cccccc;
}

.cls-1 {
  fill: #5aafa5;
}

.cls-2 {
  fill: #006fab;
}

.cls-3 {
  fill: #fff;
}

.btn-icon {
  font-size: 25px;
  color: #003369;
  margin-right: 8px;
}

.btn-icon.disabled {
  color: darkgray;
  &:hover {
    color: darkgray;
  }
}

.btn-icon:hover {
  color: #0571ab;
}

div.tooltip-inner {
  max-width: 350px;
}